.gameboard__outer {

    display: flex;
    flex-direction: row;
    justify-content: center;

    /*background-color: lightpink;*/
}

.gameboard {
    /* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.gameboard {
    /*--rows: 'set in js';*/
    /*--cols: 'set in js';*/
    /*--cell-sizepx: 'set in js';*/
    --cell-size: calc(var(--cell-sizepx) * 1px);
    --gap: calc(var(--cell-sizepx) * .00px);

    padding: 20px;

    display: grid;

    grid-template-rows: repeat(var(--rows), var(--cell-size));
    grid-template-columns: repeat(var(--cols), var(--cell-size));
    row-gap: var(--gap);
    column-gap: var(--gap);


}

.gameboard--incomplete {
    background-color: white;
}

.gameboard--complete {
    background-color: lightgreen;
}