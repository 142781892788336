@import url(https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gametile {
    background-color: var(--bg-color);
    /*color: var(--bg-color);*/
    transition: background-color 120ms ease-in-out;

    font-family: 'Fredoka One', cursive;
    text-align: center;
    font-size: calc(var(--cell-size) * .8);
    border: solid 1px rgba(211, 211, 211, 0.32);

}


.gametile__number {

}

.gametile--bulb {

    background-image: url(/static/media/bulb.3582c7fe.svg);
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.gametile--overlit-bulb {
    background-image: url(/static/media/red-bulb.e8f9f2de.svg);
}

.gametile--prohibited {

}

.gametile--empty {

}

.gametile--lit {
    --bg-color: yellow;

}

.gametile--unlit {
    --bg-color: white;
}

.gametile--wall {
    --bg-color: black;
    color: white;
}

.gametile--floor {

}

.gametile--has-constraint {

}

.gametile--oversatisfied-constraint {
    color: red;
}

.gametile--satisfied-constraint {
    color: yellow;
}

.gametile--has-no-constraint {

}

.gametile--floor.gametile--unlit.gametile--has-no-constraint:hover {
    background-color: rgba(211, 211, 211, 0.32);
}

.gameboard__outer {

    display: flex;
    flex-direction: row;
    justify-content: center;

    /*background-color: lightpink;*/
}

.gameboard {
    /* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */ /* Konqueror HTML */ /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */

    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

.gameboard {
    /*--rows: 'set in js';*/
    /*--cols: 'set in js';*/
    /*--cell-sizepx: 'set in js';*/
    --cell-size: calc(var(--cell-sizepx) * 1px);
    --gap: calc(var(--cell-sizepx) * .00px);

    padding: 20px;

    display: grid;

    grid-template-rows: repeat(var(--rows), var(--cell-size));
    grid-template-columns: repeat(var(--cols), var(--cell-size));
    grid-row-gap: var(--gap);
    row-gap: var(--gap);
    grid-column-gap: var(--gap);
    -webkit-column-gap: var(--gap);
            column-gap: var(--gap);


}

.gameboard--incomplete {
    background-color: white;
}

.gameboard--complete {
    background-color: lightgreen;
}
.header-panel {
    height: 40px;
    background-color: lightgoldenrodyellow;
    padding: 10px;
    display: flex;
    flex-direction: row;

}

.stack {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.size-input {
    width: 2em;
}

.game-button {

}

.game-button__start {

}

.game-button__rules {

}

.game-button__undo {

}

.game-button__options {

}
