.header-panel {
    height: 40px;
    background-color: lightgoldenrodyellow;
    padding: 10px;
    display: flex;
    flex-direction: row;

}

.stack {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.size-input {
    width: 2em;
}
