@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

.gametile {
    background-color: var(--bg-color);
    /*color: var(--bg-color);*/
    transition: background-color 120ms ease-in-out;

    font-family: 'Fredoka One', cursive;
    text-align: center;
    font-size: calc(var(--cell-size) * .8);
    border: solid 1px rgba(211, 211, 211, 0.32);

}


.gametile__number {

}

.gametile--bulb {

    background-image: url("../img/bulb.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}

.gametile--overlit-bulb {
    background-image: url("../img/red-bulb.svg");
}

.gametile--prohibited {

}

.gametile--empty {

}

.gametile--lit {
    --bg-color: yellow;

}

.gametile--unlit {
    --bg-color: white;
}

.gametile--wall {
    --bg-color: black;
    color: white;
}

.gametile--floor {

}

.gametile--has-constraint {

}

.gametile--oversatisfied-constraint {
    color: red;
}

.gametile--satisfied-constraint {
    color: yellow;
}

.gametile--has-no-constraint {

}

.gametile--floor.gametile--unlit.gametile--has-no-constraint:hover {
    background-color: rgba(211, 211, 211, 0.32);
}
